<template>
    <div>
        <div v-show="show" class="w-100 d-flex justify-content-center">
            <div
                id="delivery-note"
                ref="deliveryNoteTemplate"
                class="w-ticket bg-white text-dark py-4 d-flex flex-wrap"
            >
                <div class="col-12">
                    <span class="mt-2 ml-2 h3 font-weight-bold">
                        {{ deliveryNote.city }}
                    </span>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="mt-2 ml-2 h5">
                        {{
                            deliveryNote.receiverName
                                ? deliveryNote.receiverName
                                : `${deliveryNote.firstName} ${deliveryNote.lastName}`
                        }}
                    </span>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="mt-2 ml-2">
                        {{ deliveryNote.address }} |
                        {{ deliveryNote.neighborhood }} |
                        {{ deliveryNote.addressComplement }}
                    </span>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="mt-2 ml-2">
                        {{
                            deliveryNote.receiverPhone
                                ? deliveryNote.receiverPhone
                                : deliveryNote.phone
                        }}
                    </span>
                </div>

                <div class="col-12 d-flex flex-column">
                    <span class="mt-2 ml-2">
                        Ref. {{ deliveryNote.uuid }}
                    </span>
                </div>

                <div
                    class="col-12 mt-2 d-flex flex-column justify-content-center align-items-center"
                >
                    <barcode-viewer :input="deliveryNote.uuid" />
                </div>

                <div
                    class="col-12 mt-1 d-flex flex-column justify-content-center align-items-center"
                >
                    <img
                        src="/img/logos/complete_logo.png"
                        width="60"
                        alt="Logo"
                    />
                </div>
            </div>
        </div>

        <div v-if="showPrint" class="w-100 d-flex justify-content-center mt-3">
            <vs-button color="#02913f" relief @click="generateImage">
                <span class="py-1"> IMÁGEN </span>
            </vs-button>
            <vs-button color="#f07325" relief @click="generatePdf">
                <span class="py-1 mx-3"> PDF </span>
            </vs-button>
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
import { downLoadImage, elementToImage, openImage } from "@/common/lib/images";
import { downLoadPDF, elementToPDF, openPDF } from "@/common/lib/pdf";

import BarcodeViewer from "@/components/utils/BarcodeViewer.vue";

export default {
    name: "DeliveryNotePrinter",
    components: { BarcodeViewer },
    props: {
        show: {
            default: () => false,
            required: false,
            type: Boolean
        },
        deliveryNote: {
            default: () => ({
                uuid: "12123131",
                cityCode: "",
                city: "Bogota"
            }),
            required: false,
            type: Object
        },
        showPrint: {
            default: () => false,
            required: false,
            type: Boolean
        }
    },
    data: () => ({}),
    watch: {
        deliveryNote(value) {
            this.setInput(value);
        }
    },
    methods: {
        async generateImage() {
            const imgData = await elementToImage(
                document.getElementById("delivery-note")
            );
            if (!this.isPhone) {
                downLoadImage(
                    imgData.image,
                    `delivery_note_${this.deliveryNote.id}`
                );
            } else {
                openImage(imgData, `Guía de entrega ${this.deliveryNote.id}`);
            }
        },
        async generatePdf() {
            const pdf = await elementToPDF(
                document.getElementById("delivery-note")
            );
            if (this.isPhone) {
                downLoadPDF(pdf, `delivery_note_${this.deliveryNote.id}.pdf`);
            } else {
                openPDF(pdf);
            }
        },
        setInput() {
            if (!this.showPrint) {
                this.generateImage();
            }
        }
    }
};
</script>
