<template>
    <div class="mb-5 pb-5">
        <div class="my-10 pb-10 d-flex justify-content-between">
            <div>
                <h1 class="text-center">
                    Detalles de orden
                    <strong class="text-primary">
                        #{{ orderComponent.id }}
                    </strong>
                </h1>
                <strong> Código {{ orderComponent.uuid }} </strong>
            </div>

            <div class="d-flex align-items-center">
                <div>
                    <vs-button circle icon floating @click="getBaseItem">
                        <reload-icon />
                    </vs-button>
                </div>

                <vs-tooltip v-show="hasLoadedBase" border>
                    <vs-button
                        circle
                        icon
                        floating
                        @click="isOpenModalVoucher = true"
                    >
                        🖨
                    </vs-button>

                    <template #tooltip> Vale de orden</template>
                </vs-tooltip>
                <vs-tooltip
                    v-if="hasLoadedBase && orderComponent.location"
                    border
                >
                    <vs-button
                        circle
                        icon
                        floating
                        @click="isOpenModalDeliveryNote = true"
                    >
                        🚚
                    </vs-button>

                    <template #tooltip> Guía de entrega</template>
                </vs-tooltip>
            </div>
        </div>

        <div class="my-3 row">
            <vs-navbar
                id="tabs-order"
                v-model="activeTab"
                center-collapsed
                not-line
                class="position-static"
            >
                <template #left>
                    <vs-navbar-item id="home">
                        <router-link
                            :to="homeUrl"
                            class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                        >
                            <span>🏠</span>
                        </router-link>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="id"
                        id="delivery"
                        :active="activeTab === 'delivery'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-md-block d-none"> Entrega </span>
                            <span>&nbsp;🚚</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="id"
                        id="payment"
                        :active="activeTab === 'payment'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-md-block d-none"> Pago </span>
                            <span>&nbsp;💳</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="customer"
                        :active="activeTab === 'customer'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-md-block d-none"> Cliente </span>
                            <span>&nbsp;👩‍🦱</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="provider"
                        :active="activeTab === 'provider'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-md-block d-none"> Provedor </span>
                            <span>&nbsp;🏪</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="id"
                        id="products"
                        :active="activeTab === 'products'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-md-block d-none"> Referencias </span>
                            <span>&nbsp;🛍️</span>
                        </div>
                    </vs-navbar-item>
                </template>
                <template #right>
                    <div class="d-none d-md-block">
                        <vs-switch
                            v-if="$ability.hasScope('order:Update') && id"
                            v-model="enabledUpdate"
                        >
                            🖊
                        </vs-switch>
                    </div>
                    <vs-tooltip
                        v-model="showOptions"
                        bottom
                        shadow
                        not-hover
                        interactivity
                        border-thick
                    >
                        <vs-button
                            circle
                            icon
                            @click="showOptions = !showOptions"
                        >
                            <span> ⚙️ </span>
                        </vs-button>
                        <template #tooltip>
                            <div
                                class="autocomplete-items list-group text-left w-100"
                            >
                                <div
                                    v-if="
                                        orderComponent.deliveryStatus !==
                                            'cmp' &&
                                        (isMerchantOrder
                                            ? $ability.hasScope(
                                                  'order:CompleteOwn'
                                              )
                                            : $ability.hasScope(
                                                  'order:Complete'
                                              ))
                                    "
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Ir a inicio de app"
                                        @click="completeOrderComponent"
                                    >
                                        🏁&nbsp;Completar
                                    </button>
                                </div>

                                <div
                                    v-if="
                                        $ability.hasScope('order:Assign') &&
                                        !isMerchantOrder &&
                                        !orderComponent.supplyMerchant
                                    "
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Asignar a comercio"
                                        @click="assignOrderComponent"
                                    >
                                        🚚&nbsp;Asignar a
                                        {{
                                            selectedMerchant.merchantType
                                                | merchantInfo("type")
                                        }}
                                        {{ selectedMerchant.name }}
                                    </button>
                                </div>

                                <div
                                    v-if="
                                        $ability.hasScope(
                                            'order:Consultancy'
                                        ) && !isMerchantOrder
                                    "
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a"
                                        aria-label="Dividir y asignar proveedores"
                                        @click="goToRefactor"
                                    >
                                        🗜&nbsp;Dividir y asignar proveedores
                                    </button>
                                </div>

                                <div
                                    v-if="$ability.hasScope('order:Delete')"
                                    class="m-md-2 m-1"
                                >
                                    <button
                                        class="font-weight-bold btn-a-danger"
                                        aria-label="Eliminar orden"
                                        @click="deleteOrderComponent"
                                    >
                                        💀&nbsp;Eliminar orden
                                    </button>
                                </div>
                            </div>
                        </template>
                    </vs-tooltip>
                </template>
            </vs-navbar>
        </div>

        <div
            v-if="$ability.hasScope('order:Update') && id"
            class="d-flex justify-content-center"
        >
            <div class="d-block d-md-none my-3">
                <vs-switch
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>

        <div v-show="['delivery', 'home'].includes(activeTab)">
            <h3 class="text-success my-3">Detalles de entrega 🚚</h3>
            <div class="d-flex flex-wrap">
                <div class="col-12 d-flex flex-column">
                    <strong>Bitacora </strong>
                    <textarea
                        v-model="orderComponent.observation"
                        :disabled="!enabledUpdate"
                        class="vs-textarea text-base mx-2 my-2"
                        placeholder="Comentarios"
                        rows="5"
                        @blur="changeValidators.observation = true"
                    ></textarea>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Fecha de pedido </strong>
                    <span class="mt-2 ml-2">
                        {{ orderComponent.createdAt | date }}
                    </span>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Fecha de última actualización </strong>
                    <span class="mt-2 ml-2">
                        {{ orderComponent.updatedAt | date }}
                    </span>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Fecha entrega </strong>

                    <b-form-datepicker
                        v-if="enabledUpdate"
                        v-model="orderComponent.deliveryIn"
                        :date-disabled-fn="dateDisabled"
                        locale="es"
                        size="sm"
                        :min="minDeliveryDate"
                        :dark="activeDark"
                        @input="changeValidators.deliveryIn = true"
                    ></b-form-datepicker>
                    <span v-else class="mt-2 ml-2">
                        {{ orderComponent.deliveryIn | date }}
                    </span>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <delivery-option-selector
                        v-model="orderComponent.deliveryOption"
                        :enabled="false"
                        @change="changeValidators.deliveryOption = true"
                    />
                </div>

                <div
                    v-if="orderComponent.location"
                    class="w-100 d-flex flex-wrap"
                >
                    <div class="col-12 col-md-6 d-flex flex-column my-2">
                        <strong>Nombre de la persona que recibe </strong>
                        <span class="mt-2 ml-2">
                            {{
                                orderComponent.location.receiverName ||
                                "No definido"
                            }}
                        </span>
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column my-2">
                        <strong>Celular persona que recibe </strong>
                        <span class="mt-2 ml-2">
                            {{
                                orderComponent.location.receiverPhone ||
                                "No definido"
                            }}
                        </span>
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column my-2">
                        <strong>Ciudad </strong>
                        <span class="mt-2 ml-2">
                            {{ orderComponent.location.city }}
                        </span>
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column my-2">
                        <strong>Dirección </strong>
                        <span class="mt-2 ml-2">
                            {{ orderComponent.location.address }}
                            {{ orderComponent.location.addressComplement }} /
                            Barrio
                            {{ orderComponent.location.neighborhood }}
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <delivery-status-selector
                        v-model="orderComponent.deliveryStatus"
                        :enabled="enabledUpdate"
                        @change="changeValidators.deliveryStatus = true"
                    />
                </div>
            </div>
            <div
                v-if="enabledUpdate"
                class="d-flex justify-content-center my-4"
            >
                <vs-button
                    :disabled="
                        !changeValidators.observation &&
                        !changeValidators.deliveryStatus &&
                        !changeValidators.deliveryIn &&
                        !changeValidators.deliveryOption
                    "
                    @click="verifyUpdateOrderDeliveryInfo"
                >
                    Actualizar opciones de entrega
                </vs-button>
            </div>
        </div>

        <div v-if="activeTab === 'payment'">
            <h3 class="text-success my-3">Detalles de pago 💳</h3>
            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <payment-method-selector
                        v-model="orderComponent.paymentMethod"
                        :enabled="enabledUpdate"
                        @change="changeValidators.paymentMethod = true"
                    />
                </div>
                <div class="col-12 col-md-6">
                    <payment-status-selector
                        v-model="orderComponent.paymentStatus"
                        :enabled="enabledUpdate"
                        @change="changeValidators.paymentStatus = true"
                    />
                </div>
                <div class="col-12 col-md-6 my-2">
                    <strong>Valor pagado </strong>
                    <input-money-component
                        v-if="enabledUpdate"
                        v-model="orderComponent.valuePaid"
                        border
                        enabled
                        label=""
                        icon="💲"
                        :message-error="
                            orderComponent.valuePaid > orderComponent.total
                                ? `El valor pagado debe ser menor a ${orderComponent.total}`
                                : ''
                        "
                        @blur="changeValidators.valuePaid = true"
                    />
                    <div v-else class="d-flex flex-column">
                        <span class="mt-2 ml-2">
                            {{ orderComponent.valuePaid | money }}
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Valor de productos </strong>
                    <span class="mt-2 ml-2">
                        {{ orderComponent.referencesValueWithDiscount | money }}
                    </span>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Costo de entrega </strong>
                    <span class="mt-2 ml-2">
                        {{ orderComponent.deliveryPrice | money }}
                    </span>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Costo de pago </strong>
                    <span class="mt-2 ml-2">
                        {{ orderComponent.paymentPrice | money }}
                    </span>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Descuento aplicado sobre orden </strong>
                    <span class="mt-2 ml-2">
                        {{ orderComponent.appliedDiscountPerOrder | money }}
                    </span>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Valor a pagar </strong>
                    <span class="mt-2 ml-2 h2 text-success">
                        {{ orderComponent.total | money }}
                    </span>
                </div>
            </div>
            <div
                v-if="enabledUpdate"
                class="d-flex justify-content-center my-3"
            >
                <vs-button
                    :disabled="
                        !changeValidators.valuePaid &&
                        !changeValidators.paymentMethod &&
                        !changeValidators.paymentStatus
                    "
                    @click="updateOrderPaymentInfo"
                >
                    Actualizar información pago
                </vs-button>
            </div>
        </div>

        <div v-if="activeTab === 'customer'">
            <h3 class="text-success my-3">Detalles de cliente 👩‍🦱</h3>
            <div class="d-flex flex-wrap">
                <fragment v-if="orderComponent.customer">
                    <div class="col-12 col-md-6 d-flex flex-column my-2">
                        <strong>Nombre de cliente </strong>
                        <span class="mt-2 ml-2">
                            {{
                                `${orderComponent.customer.firstName} ${orderComponent.customer.lastName}`
                            }}
                        </span>
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column my-2">
                        <strong>Identificación </strong>
                        <span class="text-uppercase mt-2 ml-2">
                            {{
                                `${orderComponent.customer.identificationType} ${orderComponent.customer.identificationNumber}`
                            }}
                        </span>
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column my-2">
                        <strong>Celular de contacto </strong>
                        <span class="mt-2 ml-2">
                            {{ orderComponent.customer.phone }}
                        </span>
                    </div>
                    <div class="col-12 col-md-6 d-flex flex-column my-2">
                        <strong>Correo electrónico </strong>
                        <div class="mt-2 ml-2 link-warning">
                            <a
                                v-if="$ability.hasScope('customer:Get')"
                                :href="`/app/customers/detail/${orderComponent.customer.id}`"
                                target="_blank"
                                rel="noopener"
                            >
                                {{ orderComponent.customer.email }}
                            </a>
                            <span v-else>
                                {{ orderComponent.customer.email }}
                            </span>
                        </div>
                    </div>
                </fragment>
                <div v-else class="col-12">
                    <span> No se ha asignado a cliente </span>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'provider'">
            <h3 class="text-success my-3">Provedor y vendedor 🏪</h3>
            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong> Donde sale el inventario </strong>
                    <span
                        v-if="orderComponent.supplyMerchant"
                        class="mt-2 ml-2 link-warning"
                    >
                        <a
                            :href="`/app/${orderComponent.supplyMerchant.merchantType}s/detail/${orderComponent.supplyMerchant.merchantId}`"
                            target="_blank"
                            rel="noopener"
                            class="text-capitalize"
                        >
                            {{
                                orderComponent.supplyMerchant.merchantType
                                    | merchantInfo("type")
                            }}
                            {{ orderComponent.supplyMerchant.name }}
                        </a>
                    </span>
                    <span v-else class="mt-2 ml-2 text-warning">
                        Por definir
                    </span>
                </div>
                <div
                    v-if="orderComponent.pickupMerchant"
                    class="col-12 col-md-6 d-flex flex-column my-2"
                >
                    <strong>Donde se entrega </strong>
                    <span class="mt-2 ml-2 link-warning">
                        <a
                            :href="`/app/${orderComponent.pickupMerchant.merchantType}s/detail/${orderComponent.pickupMerchant.merchantId}`"
                            target="_blank"
                            rel="noopener"
                        >
                            {{ orderComponent.pickupMerchant.name }}
                        </a>
                    </span>
                </div>

                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Vendedor </strong>
                    <div
                        v-if="orderComponent.seller"
                        class="mt-2 ml-2 link-warning"
                    >
                        <a
                            v-if="$ability.hasScope('user:Get')"
                            :href="`/app/users/detail/${orderComponent.seller.id}`"
                            target="_blank"
                            rel="noopener"
                        >
                            {{ orderComponent.seller.email }}
                        </a>
                        <span v-else>
                            {{ orderComponent.seller.email }}
                        </span>
                    </div>
                    <span v-else class="mt-2 ml-2"> Web </span>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <strong>Origen </strong>
                    <span class="mt-2 ml-2 text-capitalize">
                        {{ orderComponent.origin }}
                    </span>
                </div>
                <div class="col-12 col-md-6 d-flex flex-column my-2">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <strong>Decrementó inventario de comercio </strong>
                        <vs-tooltip border>
                            <button
                                type="button"
                                class="btn btn-primary px-1 py-0"
                            >
                                ℹ
                            </button>
                            <template #tooltip>
                                <div class="text-left">
                                    <ul class="list-group">
                                        <li class="py-2 px-1">
                                            Si no se ha restado inventario y
                                            marcas la casilla se restará
                                            inventario del comercio.
                                        </li>
                                        <li class="py-2 px-1">
                                            En caso contrario si ya has restado
                                            inventario y desmarcas la casilla el
                                            inventario se regresará al sistema.
                                        </li>
                                        <li class="py-2 px-1">
                                            de forma automática se restará
                                            inventario (En caso de que exista)
                                            en el cambio de estado de entrega a
                                            completado.
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </vs-tooltip>
                    </div>

                    <div class="ml-2 mt-1">
                        <vs-checkbox
                            v-if="enabledUpdate"
                            v-model="orderComponent.stockDecremented"
                            warn
                            @click="changeValidators.stockDecremented = true"
                        >
                            <span>
                                {{
                                    orderComponent.stockDecremented
                                        ? "Si"
                                        : "No"
                                }}
                            </span>
                        </vs-checkbox>

                        <span v-else>
                            {{ orderComponent.stockDecremented ? "Si" : "No" }}
                        </span>
                    </div>
                </div>
                <div
                    v-if="orderComponent.orderGroupId"
                    class="col-12 col-md-6 d-flex flex-column my-2 link-warning"
                >
                    <a
                        :href="`/app/orders-groups/detail/${orderComponent.orderGroupId}`"
                        target="_blank"
                        rel="noopener"
                    >
                        Orden padre #{{ orderComponent.orderGroupId }}
                    </a>
                </div>
                <div
                    v-if="
                        !orderComponent.stockDecremented &&
                        !orderComponent.orderGroupId &&
                        enabledUpdate &&
                        $ability.hasScope('order:Consultancy')
                    "
                    class="col-12 col-md-6 d-flex flex-column my-2"
                >
                    <div>
                        <vs-button @click="goToRefactor">
                            🗜 Dividir y asignar proveedores
                        </vs-button>
                    </div>
                </div>
            </div>

            <div
                v-if="enabledUpdate"
                class="d-flex justify-content-center my-4"
            >
                <vs-button
                    :disabled="!changeValidators.stockDecremented"
                    @click="updateStoreInfo"
                >
                    Actualizar información de proveedor
                </vs-button>
            </div>
        </div>

        <div v-if="activeTab === 'products'">
            <h3 class="text-success my-3">Detalles de referencias 🪀</h3>
            <div v-if="orderComponent.anonymousReferences.length">
                <h4 class="mt-3">Servicios / Referencias anónimas</h4>
                <span v-show="enabledUpdate" class="my-3">
                    Esta orden contiene servicios. No puedes editar actualizar
                    la información de servicios.
                </span>
                <reference-table-items
                    v-for="(
                        reference, index
                    ) in orderComponent.anonymousReferences"
                    :key="index"
                    :reference="reference"
                    class="col-12 mx-auto"
                />
            </div>

            <h4 class="mt-3">Referencias</h4>

            <span v-show="enabledUpdate" class="my-3">
                Tienes la capacidad de editar la cantidad de unidades para cada
                una de las referencias, así como la posibilidad de agregar o
                eliminar referencias según tus necesidades.
            </span>

            <references-search-scanner
                v-if="enabledUpdate"
                class="col-12 mx-auto"
                :enabled-scanner="enabledScanner"
                @input="validatePushReferences"
            />

            <div v-if="orderComponent.references.length">
                <reference-table-items
                    v-for="(reference, index) in orderComponent.references"
                    :key="index"
                    :enabled="enabledUpdate"
                    :reference="reference"
                    :merchant="orderComponent.supplyMerchant"
                    enabled-discount
                    class="col-12 mx-auto mt-2"
                    @update="changeQuantityReference"
                    @remove="removeReference"
                    @focus="onFocusSomeInput"
                />
            </div>
            <div v-else>
                <span>Esta orden no tiene referencias asociadas.</span>
            </div>

            <div
                v-if="enabledUpdate"
                class="d-flex justify-content-center my-4"
            >
                <vs-button
                    :disabled="!changeValidators.references"
                    @click="updateReferences"
                >
                    Actualizar referencias
                </vs-button>
            </div>
        </div>

        <vs-dialog
            v-model="isOpenModalDeliveryNote"
            scroll
            overflow-hidden
            auto-width
        >
            <template #header>
                <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                    <span class="h3">
                        Guia de entrega
                        <span class="text-primary">
                            # {{ orderComponent.id }}
                        </span>
                    </span>
                </div>
            </template>
            <div class="con-content">
                <delivery-note-printer
                    show
                    show-print
                    :delivery-note="{
                        ...orderComponent.location,
                        ...orderComponent.customer,
                        uuid: orderComponent.uuid
                    }"
                />
            </div>
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalVoucher"
            scroll
            overflow-hidden
            auto-width
        >
            <template #header>
                <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                    <span class="h3">
                        Vale de orden
                        <span class="text-primary">
                            # {{ orderComponent.id }}
                        </span>
                    </span>
                </div>
            </template>
            <div class="con-content">
                <order-printer show show-print :order="orderComponent" />
            </div>
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalSelectAddress"
            scroll
            overflow-hidden
            auto-width
            @close="reloadPage"
        >
            <template #header>
                <div
                    class="mx-4 mt-2 d-flex justify-content-center align-items-center flex-column flex-lg-row"
                >
                    <span class="h4"> Selecciona dirección de entrega </span>
                </div>
            </template>
            <div class="con-content">
                <div class="mx-3 my-4"></div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import { validateConfirmationMessage } from "@/common/lib/transformers";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import DeliveryOptionSelector from "@/components/orders/DeliveryOptionSelector.vue";
import DeliveryStatusSelector from "@/components/orders/DeliveryStatusSelector.vue";
import OrderPrinter from "@/components/orders/OrderPrinter.vue";
import PaymentMethodSelector from "@/components/orders/PaymentMethodSelector.vue";
import PaymentStatusSelector from "@/components/orders/PaymentStatusSelector.vue";
import ReferenceTableItems from "@/components/references/ReferenceTableItems.vue";
import ReferencesSearchScanner from "@/components/references/ReferencesSearchScanner.vue";
import DeliveryNotePrinter from "@/components/utils/DeliveryNotePrinter.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "DetailOrder",
    components: {
        ReloadIcon,
        OrderPrinter,
        ReferenceTableItems,
        InputMoneyComponent,
        PaymentMethodSelector,
        PaymentStatusSelector,
        DeliveryStatusSelector,
        DeliveryOptionSelector,
        ReferencesSearchScanner,
        DeliveryNotePrinter
    },
    data: () => ({
        orderComponent: {
            id: 4,
            billFileId: null,
            orderGroupId: null,
            sellerId: 0,
            prepareTime: 3,
            prepareTimeRMS: 5,
            deliveryIn: "2022",
            observation: "Cargando...",
            discounts: 0,
            total: 56000,
            valuePaid: 0,
            paymentStatus: "pnd",
            paymentPrice: null,
            paymentMethod: "cashondelivery",
            paymentAt: null,
            deliveryStatus: "pnd",
            deliveryOption: "home",
            deliveryPrice: 6000,
            referencesValueWithDiscount: 6000,
            appliedDiscountPerOrder: 0,
            deliveryAt: null,
            createdAt: "2022-01-28T17:11:50.406Z",
            updatedAt: "2022-01-28T17:11:50.406Z",
            supplyMerchant: {
                name: "KND",
                id: "",
                address: "Calle",
                type: "store"
            },
            pickupMerchant: {
                name: "KND",
                id: "",
                address: "Calle",
                type: "store"
            },
            seller: { email: "@gmail.com", id: "" },
            referencesSnapshot: [],
            anonymousReferences: [],
            references: [],
            customer: {
                id: 4,
                identificationType: "cc",
                identificationNumber: "Cargando...",
                firstName: "Usuario",
                lastName: "Cargando...",
                email: "Cargando...",
                phone: "Cargando..."
            },
            location: {
                id: 2,
                address: "Cargando...",
                addressComplement: "",
                cityCode: "11001",
                city: "Bogotá",
                neighborhood: "Cargando...",
                coordinates: { latitude: 4.63082, longitude: -74.15035 },
                receiverPhone: "Cargando ..",
                receiverName: "Cargando .."
            },
            type: "web",
            stockDecremented: false
        },
        loaderInstance: null,
        isMerchantOrder: false,
        isOpenModalVoucher: false,
        isOpenModalDeliveryNote: false,
        isOpenModalSelectAddress: false,
        isLoading: false,
        hasLoadedBase: false,
        showOptions: false,
        id: 0,
        enabledUpdate: false,
        activeTab: "delivery",
        merchant: {
            merchantId: "",
            merchantType: ""
        },
        changeValidators: {
            observation: false,
            valuePaid: false,
            paymentStatus: false,
            deliveryOption: false,
            deliveryStatus: false,
            paymentMethod: false,
            references: false,
            stockDecremented: false,
            deliveryIn: false
        },
        minDeliveryDate: DateTime.now().toFormat("yyyy-MM-dd"),
        enabledScanner: false,
        homeUrl: "/app/orders"
    }),

    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"]),
        ...mapState("orders", ["order"])
    },
    watch: {
        enabledUpdate(value) {
            this.enabledScanner = value;
            this.SET_EDIT_ORDER(value);
        }
    },
    created() {
        this.$watch(
            () => this.$route,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    location.reload();
                }
            },
            {
                deep: true
            }
        );
    },
    async mounted() {
        this.isMerchantOrder = this.$route.name === "OrdersReducedDetail";
        this.homeUrl = this.$enabledEcommerceOptions
            ? "/app/orders"
            : "/app/orders/own";
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (this.isMerchantOrder) {
            if (!(await verifyPageScope("order:GetOwn", this.homeUrl))) return;
            const { id, merchantId, merchantType } = this.$route.params;
            this.id = parseInt(id);
            if (merchantId && merchantType) {
                this.merchant = { merchantId, merchantType };
            } else {
                this.$router.push(this.homeUrl);
            }
            this.getReducedBaseItem();
            return;
        }
        if (!(await verifyPageScope("order:Get", this.homeUrl))) return;
        const { id } = this.$route.params;
        this.id = parseInt(id);
        this.getBaseItem();
    },
    methods: {
        ...mapMutations("orders", ["SET_EDIT_ORDER"]),
        ...mapActions("orders", [
            "getOrderOwnById",
            "getOrderById",
            "updateOrder",
            "deleteOrder",
            "completeOrder",
            "assignOrder"
        ]),
        async getReducedBaseItem() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                this.orderComponent = await this.getOrderOwnById({
                    id: this.id,
                    ...this.merchant
                });
                this.hasLoadedBase = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });

                if (error.statusCode === 404) {
                    this.$router.push(this.homeUrl);
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async getBaseItem() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                this.orderComponent = await this.getOrderById({ id: this.id });
                this.hasLoadedBase = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push(this.homeUrl);
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async verifyUpdateOrderDeliveryInfo() {
            if (
                this.changeValidators.deliveryOption &&
                this.orderComponent.deliveryOption === "home"
            ) {
                this.isOpenModalSelectAddress = true;
                return;
            }
            const dataToUpdate = {
                id: this.orderComponent.id,
                ...{
                    ...(this.changeValidators.deliveryStatus
                        ? { deliveryStatus: this.orderComponent.deliveryStatus }
                        : null)
                },
                ...{
                    ...(this.changeValidators.deliveryOption
                        ? { deliveryOption: this.orderComponent.deliveryOption }
                        : null)
                },
                ...{
                    ...(this.changeValidators.observation
                        ? { observation: this.orderComponent.observation }
                        : null)
                },
                ...{
                    ...(this.changeValidators.deliveryIn
                        ? { deliveryIn: this.orderComponent.deliveryIn }
                        : null)
                }
            };
            this.updateOrderDeliveryInfo(dataToUpdate);
        },
        async updateOrderDeliveryInfo(dataToUpdate) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                await this.updateOrder(dataToUpdate);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
                this.resetValidators();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async updateOrderPaymentInfo() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            const dataToUpdate = {
                id: this.orderComponent.id,
                ...{
                    ...(this.changeValidators.valuePaid
                        ? { valuePaid: this.orderComponent.valuePaid }
                        : null)
                },
                ...{
                    ...(this.changeValidators.paymentStatus
                        ? { paymentStatus: this.orderComponent.paymentStatus }
                        : null)
                },
                ...{
                    ...(this.changeValidators.paymentMethod
                        ? { paymentMethod: this.orderComponent.paymentMethod }
                        : null)
                }
            };

            try {
                await this.updateOrder(dataToUpdate);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
                this.resetValidators();
            }
        },
        async updateReferences() {
            this.isLoading = true;
            let alterInventory = false;
            if (
                !this.orderComponent.stockDecremented &&
                this.orderComponent.supplyMerchant
            ) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Deseas afectar inventario",
                    text: "Puedes verificar si hay suficiente stock para las nuevas referencias que agregaste o las referencias que cambiaste la cantidad. En caso de disminuir cantidad puedes la diferencia regresa al inventario.",
                    icon: "info",
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonColor: "#02672D",
                    denyButtonColor: "#253C99",
                    confirmButtonText: "Si Afectar inventario",
                    denyButtonText: "Solo Actualizar"
                });
                if (dialogResult.isConfirmed) {
                    alterInventory = true;
                } else if (dialogResult.isDenied) {
                    alterInventory = false;
                } else {
                    return;
                }
            }

            this.loaderInstance = this.$vs.loading({ type: "square" });
            const dataToUpdate = {
                alterInventory,
                id: this.orderComponent.id,
                ...{
                    ...(this.changeValidators.references
                        ? {
                              references: this.orderComponent.references.map(
                                  (item) => ({
                                      id: item.id,
                                      quantity: item.quantity,
                                      appliedDiscountPerReference:
                                          item.appliedDiscountPerReference || 0
                                  })
                              )
                          }
                        : null)
                }
            };

            try {
                const resultReferences = await this.updateOrder(dataToUpdate);
                this.orderComponent = {
                    ...this.orderComponent,
                    ...resultReferences
                };
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
                this.resetValidators();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async updateStoreInfo() {
            const dataToUpdate = {
                id: this.orderComponent.id,
                ...{
                    ...(this.changeValidators.stockDecremented
                        ? {
                              stockDecremented:
                                  this.orderComponent.stockDecremented
                          }
                        : null)
                }
            };
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square" });
            try {
                await this.updateOrder(dataToUpdate);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
                this.resetValidators();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                this.orderComponent = {
                    ...this.orderComponent,
                    stockDecremented: Boolean(this.order.stockDecremented)
                };
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async deleteOrderComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: `Vas a eliminar la orden ${this.id}`,
                text: `Las ordenes solo deben eliminarse en caso de que se esten haciendo pruebas, eres consiente de lo que estas haciendo ?. Escribe 'Si' para eliminar `,
                icon: "error",
                input: "text",
                confirmButtonColor: "#ee3f59",
                cancelButtonColor: "#253C99",
                showCancelButton: true
            });
            if (
                dialogResult.isConfirmed &&
                validateConfirmationMessage(dialogResult.value)
            ) {
                try {
                    await this.deleteOrder({ id: this.id });
                    this.$vs.notification({
                        title: `Orden # ${this.id} eliminada`,
                        color: "primary",
                        icon: "🚧"
                    });
                    this.$router.push(this.homeUrl);
                } catch (error) {
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                }
            }
        },
        async completeOrderComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                icon: "info",
                title: `Vas a completar la orden ${this.id}`,
                text: `Al completar la orden vas a:
Actualizar el estado de entrega a completado. 
Actualizar el estado de pago a completado.
Actualizar el valor pagado al valor total de la orden.
Decrementar la cantidad de productos de la orden en el inventario del comercio asignado. En caso de no haber sido decrementado anteriormente.`,
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (!dialogResult.isConfirmed) return;

            this.loaderInstance = this.$vs.loading({ type: "square" });

            try {
                const resultComplete = await this.completeOrder({
                    id: this.id
                });
                this.orderComponent = {
                    ...this.orderComponent,
                    ...resultComplete
                };
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
                this.loaderInstance.close();
                this.resetValidators();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        async assignOrderComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                icon: "info",
                title: `Vas a asignar la orden ${this.id}`,
                text: `Vas a asignar a comercio ${this.selectedMerchant.name}. Desear validar el inventario del comercio con las referencias de la orden ?`,
                showDenyButton: true,
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonColor: "#02672D",
                denyButtonColor: "#253C99",
                cancelButtonColor: "#EA5455",
                confirmButtonText: "Si",
                denyButtonText: "No",
                cancelButtonText: "Cancelar"
            });
            if (dialogResult.isDismissed) return;

            this.loaderInstance = this.$vs.loading({ type: "square" });

            try {
                const resultAsign = await this.assignOrder({
                    id: this.id,
                    merchantType: this.selectedMerchant.merchantType,
                    merchantId: this.selectedMerchant.merchantId,
                    validateStock: dialogResult.isConfirmed
                });
                this.orderComponent = {
                    ...this.orderComponent,
                    ...resultAsign
                };
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
                this.loaderInstance.close();
                this.resetValidators();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        dateDisabled(ymd, date) {
            // Disable weekends (Sunday = `0`, Saturday = `6`) and
            // disable days that fall on the 13th of the month
            const weekday = date.getDay();
            //const day = date.getDate();
            // Return `true` if the date should be disabled
            return [0, 6].includes(weekday);
        },
        resetValidators() {
            this.changeValidators = {
                valuePaid: false,
                observation: false,
                paymentStatus: false,
                deliveryOption: false,
                deliveryStatus: false,
                paymentMethod: false,
                reference: false,
                stockDecremented: false,
                deliveryIn: false
            };
        },
        validatePushReferences(reference) {
            const index = this.orderComponent.references.findIndex(
                (item) => item.id === reference.id
            );

            if (index === -1) {
                this.orderComponent.references.unshift({
                    ...reference,
                    quantity: 1
                });
            } else {
                let references = [...this.orderComponent.references];
                references[index] = {
                    ...references[index],
                    quantity: references[index].quantity + 1
                };
                this.orderComponent.references = [...references];
            }
            this.calculateTotals();
        },
        changeQuantityReference({ appliedDiscountPerReference, id, quantity }) {
            this.onBlurSomeInput();
            const index = this.orderComponent.references.findIndex(
                (item) => item.id == id
            );
            let references = [...this.orderComponent.references];
            references[index] = {
                ...references[index],
                quantity,
                appliedDiscountPerReference
            };
            this.orderComponent.references = [...references];
            this.calculateTotals();
        },
        removeReference({ id }) {
            if (this.orderComponent.references.length === 1) {
                return this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Operación NO permitida",
                    text: "La orden debe quedar como mínimo con un 1 referencia",
                    icon: "info"
                });
            }
            const index = this.orderComponent.references.findIndex(
                (item) => item.id === id
            );
            let references = [...this.orderComponent.references];
            references.splice(index, 1);
            this.orderComponent.references = [...references];
            this.calculateTotals();
        },
        calculateTotals() {
            this.orderComponent.total = [
                ...this.orderComponent.references,
                ...this.orderComponent.anonymousReferences
            ].reduce(
                (a, b) =>
                    a +
                    (b.priceOffer - b.appliedDiscountPerReference) * b.quantity,
                0
            );
            this.changeValidators.references = true;
        },
        onFocusSomeInput() {
            this.enabledScanner = false;
        },
        onBlurSomeInput() {
            this.enabledScanner = true;
        },
        reloadPage() {
            this.$router.go(this.$router.currentRoute);
        },
        goToRefactor() {
            this.showOptions = false;
            window
                .open(
                    `/app/orders/refactor/${this.orderComponent.id}`,
                    "_blank"
                )
                .focus();
        }
    }
};
</script>
